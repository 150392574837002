<template>
  <v-container>
    <v-progress-linear
      v-show="resultsLoading"
      indeterminate
      color="primary"
    />
    <v-row
      v-for="(item, i) in results"
      :key="'item' + i"
      class="mt-1"
      justify="space-between"
    >
      <v-col md="7">
        <v-card-title v-if="fileType === 1">
          {{ item.mailName }}
        </v-card-title>
        <v-card-title v-else-if="fileType === 10">
          {{ item.nameCur }}
        </v-card-title>
        <v-card-title v-else-if="fileType === 23">
          {{ item.mailName }}
        </v-card-title>
        <v-card-title v-else-if="fileType === 28">
          {{ `${item.firstName} ${item.lastName}` }}
        </v-card-title>
      </v-col>
      <v-col
        md="5"
        class="mt-4 pl-10"
      >
        <v-btn
          v-show="fileType === 1"
          v-model="buttonLogic"
          tile
          small
          color="primary"
          @click.stop="dialog = true"
        >
          {{ getButtonTextLogic() }}
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="fileType === 1">
      <div
        v-for="(res, i) in results"
        :key="'res' + i"
      >
        <v-row justify="space-between">
          <v-col md="5">
            <v-card-text>
              <v-list-item-content>
                <v-list-item-subtitle><span class="font-weight-medium">MMS ID Number: </span>{{ mixedMe }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">Preferred Address: </span>{{ res.mailAddress1 }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ res.mailAddress2 }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ getFormattedAddress(res) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">Address Type: </span>{{ getAddressType(res.addressType) }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">Phone Number: </span>{{ getFormattedPhoneNumber(res.phoneNumber) }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">PMSA of Preferred Mailing Address: </span><span style="white-space: initial">{{ res.pmsA_MSA === '' ? '(0000) PMSA description not found.' : res.pmsA_MSA }}</span></v-list-item-subtitle>
              </v-list-item-content>
            </v-card-text>
          </v-col>
          <v-divider
            vertical
            class="mt-5 mb-4"
          />
          <v-col
            md="5"
            class="mt-4"
          >
            <v-list-item-content v-if="res.omitMeDisplay !== null">
              <v-list-item-subtitle><span class="red--text text--lighten-1 ml-4"> {{ res.omitMeDisplay }}</span></v-list-item-subtitle>
              <v-list-item-subtitle><span class="red--text text--lighten-1 ml-4">Omitted by: {{ res.omittedByMe }}</span></v-list-item-subtitle>
              <v-list-item-subtitle><span class="red--text text--lighten-1 ml-4">Omitted On: {{ res.omittedDateMe | displayDateTimeFormat }}</span></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-subtitle>
                <span
                  class="font-weight-medium"
                >
                  This doctor is presumed dead:
                </span>
                <span v-if="res.deadFlag === true">
                  <span class="red--text text--lighten-1">Yes</span>
                </span>
                <span v-else>
                  <span>No</span>
                </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span
                  class="font-weight-medium"
                >
                  This doctor has an undeliverable address:
                </span>
                <span v-if="res.undeliverable === true">
                  <span class="red--text text--lighten-1">Yes</span>
                </span>
                <span v-else>
                  <span>No</span>
                </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span
                  class="font-weight-medium"
                >
                  This doctor has asked not to be contacted:
                </span>
                <span v-if="res.contactFlag === false">
                  <span class="red--text text--lighten-1">Yes</span>
                </span>
                <span v-else>
                  <span>No</span>
                </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span
                  class="font-weight-medium"
                >
                  Type of practice summary inactive:
                </span>
                <span v-if="topsDescription === 'Inactive'">
                  <span class="red--text text--lighten-1">Yes</span>
                </span>
                <span v-else>
                  <span>No</span>
                </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle />
              <v-list-item-subtitle class="mt-5">
                <span
                  class="font-weight-medium "
                >
                  This doctor is omitted from mailings:
                </span>
                <span v-if="res.omitFlag !== null">
                  <span class="red--text text--lighten-1">Yes</span>
                </span>
                <span v-else>
                  <span>No</span>
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-col>
          <v-dialog
            v-model="dialog"
            max-width="290"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Caution:</span>
              </v-card-title>
              <v-card-text v-if="res.omitMeDisplay === null">
                This profile/person will be added to the <span style="font-weight:bold">{{ fileTypeName }} </span> Omit list.
              </v-card-text>
              <v-card-text v-else>
                This profile/person will be removed from the <span style="font-weight:bold">{{ fileTypeName }} </span> Omit list.
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="tertiary"
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>

                <v-btn
                  color="success"
                  @click="getOmitLogic()"
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-divider class="ml-4 mr-4" />
        <v-row>
          <v-col cols="6">
            <v-card-text>
              <p class="font-weight-bold">
                Basic Information
              </p>
              <v-list-item-content>
                <v-list-item-subtitle><span class="font-weight-medium">Type of Practice Summary: </span>{{ getTopsDescription() }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">Type of Practice: </span>{{ getTypeOfPractice() }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">Present Employment: </span>{{ res.peDesc }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">Primary Specialty: </span>{{ res.primSpecDesc }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">Secondary Specialty: </span>{{ res.secSpecDesc }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">YOB: </span>{{ res.yob }}</v-list-item-subtitle>
                <v-list-item-subtitle v-show="res.board1Desc !== null">
                  <span class="font-weight-medium">Board 1: </span>{{ res.board1Desc }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-show="res.board2Desc !== null">
                  <span class="font-weight-medium">Board 2: </span>{{ res.board2Desc }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-show="res.board3Desc !== null">
                  <span class="font-weight-medium">Board 3: </span>{{ res.board3Desc }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-show="res.board4Desc !== null">
                  <span class="font-weight-medium">Board 4: </span>{{ res.board4Desc }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-show="res.board5Desc !== null">
                  <span class="font-weight-medium">Board 5: </span>{{ res.board5Desc }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-show="res.board6Desc !== null">
                  <span class="font-weight-medium">Board 6: </span>{{ res.board6Desc }}
                </v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">Graduation Year : </span>{{ res.medSchoolYOG }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">Medical School : </span><span style="white-space: initial">{{ res.medSchool }}</span></v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">Transition Year : </span>{{ res.yot }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium"> Gender : </span>{{ res.sex }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-card-text>
          </v-col>
          <v-divider
            vertical
            class="mt-5 mb-4"
          />
          <v-col cols="6">
            <v-card-text>
              <p class="font-weight-bold">
                Residency / Fellowship information
              </p>
              <v-list-item-content
                v-for="(resi, j) in res.residences"
                :key="'resi' + j"
              >
                <v-list-item-subtitle><span class="font-weight-medium">Program: </span>{{ resi.nameInst }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ resi.cityState }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">Specialty </span>{{ resi.specialtyName }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">Start Date: </span>{{ resi.startYear }}</v-list-item-subtitle>
                <v-list-item-subtitle><span class="font-weight-medium">End Date: </span>{{ resi.endYear }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-card-text>
            <v-divider class="ml-4 mr-4" />
            <v-card-text>
              <p class="font-weight-bold">
                Demographic Information
              </p>
              <v-list-item-content>
                <v-list-item-subtitle><span class="font-weight-medium">Birthplace: </span>{{ `${res.birthCity}, ${res.birthState}` }}</v-list-item-subtitle>
                <div
                  v-for="(lic, k) in res.licenses"
                  :key="'lic' + k"
                >
                  <v-list-item-subtitle><span class="font-weight-medium">License: </span>{{ lic.license }}</v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-card-text>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-if="fileType === 10">
      <v-row
        v-for="(item, j) in results"
        :key="j"
      >
        <v-col>
          <v-card-text>
            <v-list-item-content>
              <v-list-item-subtitle><span class="font-weight-medium">MMS ID Number: </span>{{ mixedMe }}</v-list-item-subtitle>
              <v-list-item-subtitle><span class="font-weight-medium">Preferred Address: </span>{{ item.address1 }}</v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ item.address2 }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ getFormattedAddress(item) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-card-text>
        </v-col>
      </v-row>
    </template>
    <template v-if="fileType === 23">
      <v-row
        v-for="(item, k) in results"
        :key="k"
      >
        <v-col>
          <v-card-text>
            <v-list-item-content>
              <v-list-item-subtitle><span class="font-weight-medium">MMS ID Number: </span>{{ mixedMe }}</v-list-item-subtitle>
              <v-list-item-subtitle><span class="font-weight-medium">Preferred Address: </span>{{ item.address1 }}</v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ item.address2 }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ getFormattedAddress(item) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-card-text>
        </v-col>
      </v-row>
    </template>
    <template v-if="fileType === 28">
      <v-row
        v-for="(item, l) in results"
        :key="l"
      >
        <v-col>
          <v-card-text>
            <v-list-item-content>
              <v-list-item-subtitle><span class="font-weight-medium">MMS ID Number: </span>{{ mixedMe }}</v-list-item-subtitle>
              <v-list-item-subtitle><span class="font-weight-medium">Preferred Address: </span>{{ item.address1 }}</v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ item.address2 }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ getFormattedAddress(item) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-card-text>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { nowwService } from '@/shared/services'

export default {
  name: 'DrLookupTable',

  props: {
    drId: {
      type: String,
      default: null
    },
    mixedMe: {
      type: String,
      default: null
    },
    fileType: {
      type: Number,
      default: null
    },
    initAddress: {
      type: String,
      default: null
    },
    fileTypeName: {
      type: String,
      default: null
    },
    tenantCrmId: {
      type: Number,
      default: null
    },
    customerNumber: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      resultsLoading: false,
      results: [],
      details: [],
      residenceDetails: [],
      licenseDetails: [],
      buttonLogic: 'Add',
      reload: false,
      dialog: false,
      topsDescription: ''
    }
  },

  created () {
    this.loadDetails()
  },

  methods: {
    ...mapActions('auth', ['setViewAsCustomer']),
    getAddressType (value) {
      var addressType = ''
      switch (value) {
        case 1:
          addressType = 'Professional'
          return addressType
        case 2:
          addressType = 'Home'
          return addressType
        case 3:
          addressType = 'Home in Office'
          return addressType
        default:
          addressType = 'Undefined'
          return addressType
      }
    },
    getFormattedPhoneNumber (phone) {
      var phoneNumber = ''
      var cleaned = ('' + phone).replace(/\D/g, '')
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        phoneNumber = '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return phoneNumber
    },
    getFormattedAddress (value) {
      var address = ''
      if (this.fileType === 1) {
        address = `${value.mailCity}, ${value.mailState} ${value.mailZipCode}`
      }
      if (this.fileType === 10) {
        address = `${value.city}, ${value.state} ${value.zip}`
      }
      if (this.fileType === 23) {
        address = `${value.city}, ${value.state} ${value.zip}`
      }
      if (this.fileType === 28) {
        address = `${value.city}, ${value.state} ${value.zip10}`
      }
      return address
    },
    getOmitMe () {
      var omitCode = this.results.find(function (omit) {
        return omit.omitME
      })
      return omitCode.omitME
    },
    getTopsDescription () {
      var findTop = this.results.find(function (top) {
        return top.tops
      })
      if (findTop.topsDesc === null) {
        this.topsDescription = findTop.tops === 'IN' ? 'Inactive' : 'Not Classified'
      } else {
        this.topsDescription = findTop.topsDesc
      }
      return this.topsDescription
    },
    getTypeOfPractice () {
      var findPractice = this.results.find(function (top) {
        return top.tops
      })
      var tyeOfPracticeDescription = ''
      if (findPractice.topPrimaryDesc === null) {
        switch (findPractice.primaryTOP) {
          case '070':
            tyeOfPracticeDescription = 'Inactive'
            break
          case '071':
            tyeOfPracticeDescription = 'Retired'
            break
          case '072':
            tyeOfPracticeDescription = 'Semi-Retired'
            break
          case '073':
            tyeOfPracticeDescription = 'Disabled'
            break
          case '074':
            tyeOfPracticeDescription = 'Temporarily Not In Practice'
            break
          case '075':
            tyeOfPracticeDescription = 'Not Active For Other Reasons'
            break
          default:
            tyeOfPracticeDescription = 'No Classification'
            break
        }
      } else {
        tyeOfPracticeDescription = findPractice.topPrimaryDesc
      }
      return tyeOfPracticeDescription
    },
    getButtonTextLogic () {
      var btnText = ''
      var omitCode = this.results.map(x => x.omitMeDisplay)
      if (omitCode.includes(null)) {
        btnText = 'Add to omit list'
      } else {
        btnText = 'Remove from omit list'
      }
      return btnText
    },
    getOmitLogic () {
      var omitCode = this.results.map(x => x.omitMeDisplay)
      if (omitCode.includes(null)) {
        this.addToOmit()
        this.reload = true
        this.dialog = false
        this.loadDetails()
      } else {
        this.removeFromOmit()
        this.reload = true
        this.dialog = false
        this.loadDetails()
      }
    },
    async loadDetails () {
      var _this = this
      var userId = _this.$store.getters['auth/getUser']().userId
      _this.resultsLoading = true
      if (_this.tenantCrmId === -1) {
        _this.$store.commit('setGlobalSnackbar', {
          message: 'Please select a valid customer',
          color: 'warning'
        })
      } else {
        await nowwService.getDrLookupDetails({
          fileType: _this.fileType,
          customerId: _this.customerNumber,
          tenantCrmId: _this.tenantCrmId,
          userId: userId,
          meNumber: _this.drId,
          reload: _this.reload
        })
          .then(function (resp) {
            if (resp.errorResponse) {
              _this.$store.commit('setGlobalSnackbar', {
                message: resp.errorResponse,
                color: 'error'
              })
            } else {
              _this.results = resp
              _this.resultsLoading = false
            }
          })
      }
    },
    async addToOmit () {
      var _this = this
      var customerName = _this.$store.getters['auth/getUser']().fullName
      var omitMe = _this.getOmitMe()
      await nowwService.drLookupAddToOmit({
        omitCode: omitMe,
        meNumber: _this.drId,
        customerName: customerName,
        tenantCrmId: _this.$store.getters['auth/getTenantCrmId']()
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
          }
        })
    },
    async removeFromOmit () {
      var _this = this
      var customerName = _this.$store.getters['auth/getUser']().fullName
      var omitMe = _this.getOmitMe()
      await nowwService.drLookupRemoveFromOmit({
        omitCode: omitMe,
        meNumber: _this.drId,
        customerName: customerName
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
